export function MerchantData() {
  return {
    code: '',
    name: '',
    user: '',
    password: '',
    secret_code: '',
    type_payment: '',
    callback_url: '',
    from_date: '',
    to_date: '',
    current_balance: '',
    bank: new MerchantBank(),
  }
}

export function UpdateMerchantData() {
  return {
    code: '',
    name: '',
    user: '',
    password: '',
    secret_code: '',
    type_payment: '',
    callback_url: '',
    from_date: '',
    to_date: '',
    current_balance: '',
    bank: new MerchantBank(),
  }
}

export function MerchantBank() {
  return {
    bank_account: '',
    bank_name: '',
    bank_branch: '',
  }
}

export function TypeOfPayment() {
  return [
    {
      value: '01',
      text: 'deposit',
    },
    {
      value: '02',
      text: 'settlement',
    },
  ]
}
