<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.code"
            label="Code"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.name"
            sm="12"
            cols="6"
            label="Name"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.user"
            sm="12"
            cols="6"
            label="User"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.password"
            sm="12"
            cols="6"
            label="Password"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.secret_code"
            sm="12"
            cols="6"
            label="Secret Code"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
            dense
            v-model="merchant_model.type_payment"
            label="Payment Type"
            :items="payment_type"
            clearable
            :rules="[v => !!v || 'Payment type must filled']"
        ></v-autocomplete>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.callback_url"
            sm="12"
            cols="6"
            label="Callback Url"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.from_date"
            sm="12"
            cols="6"
            label="From Date"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.to_date"
            sm="12"
            cols="6"
            label="To Date"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.bank.bank_name"
            sm="12"
            cols="6"
            label="Bank Name"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.bank.bank_account"
            sm="12"
            cols="6"
            label="Bank Account"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_model.bank.bank_branch"
            sm="12"
            cols="6"
            label="Bank Branch"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "MerchantFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
  }),
  props: {
    merchant_model: Object,
    payment_type: [],
  }
}
</script>

<style scoped>

</style>
